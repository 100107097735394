/* eslint-disable camelcase */
import React, { useState, useEffect } from "react"
import { useStoreState, useStoreActions } from "easy-peasy"
import { graphql } from 'gatsby'
import Layout from '@layouts/MainLayout'
import Container from '@layouts/Container'
import Grid from '@lib/ui/core/Grid'
import SEO from '@components/SEO'
import Post from '@components/Post/PostList'
import MobilePost from '@components/Post/PostList/MobileList'
import { parsePost } from '@services/prismic/helpers'
import Box from '@lib/ui/core/Box'
import Link from '@components/Link'
import Pagination from '@material-ui/lab/Pagination'
import PaginationItem from '@material-ui/lab/PaginationItem'
import SearchInput from "@components/Search/SearchInput"
import FilteredBanner from "@components/Post/FilteredBanner"
import Typography from '@lib/ui/core/Typography'
import DfpAdSlot from '@components/Ads/DfpAdSlot'
import FacebookPage from "@components/Widget/FacebookPage"
import SubcategoryBlock from '@components/Post/SubcategoryBlock'

const path = '/posts'
const PostsPage = ({ data, location }) => {
  if (!data) return null
  const docs = data.allPrismicPost
  const { currentPage, pageCount } = docs.pageInfo

  const [isMobile, setIsMobile] = useState(true)
  const windowGlobal = typeof window !== 'undefined' && window // eslint-disable-line
  const deviceWidth = windowGlobal ? windowGlobal.document.documentElement.clientWidth : 0

  useEffect(() => {
    setIsMobile(deviceWidth <= 768)
  }, [deviceWidth])


  return (
    <Layout headerTitle="所有文章" type="post">
      <SEO
        title="所有文章"
      />
      <Container>
        <Box mb={2} mt={4}>
          <Typography variant="h2">所有文章</Typography>
        </Box>
        <Box display="none" position="relative" mt="-1.5rem" mb={{ xs: 2.5, sm: 4 }}>
          <SearchInput height="10rem" />
        </Box>
        <FilteredBanner location={location} />
      </Container>
      <Container>
      <Box mx="auto" my={{ xs: 1.5, sm: 3 }} data-ele-section="posts-list">
        <Grid container spacing={2}>
          {/* {posts.map((post) => <Box key={post.prismicId} mb={2.5}><Post post={post} fullWidth /></Box>)} */}
          <Grid item xs={12} sm={12} md={8}>
            <Post posts={parsePost(docs.nodes)} fullWidth />
            <Box textAlign="center" spacing={2} display="flex" justifyContent="center">
              <Pagination
                page={currentPage}
                count={pageCount}
                renderItem={(item) => (
                  <PaginationItem
                    component={Link}
                    to={`${path}/${item.page === 1 ? '' : `${item.page}`}`}
                    {...item}
                  />
                )}
              />
            </Box>
          </Grid>
          <Grid item xs={0} sm={0} md={4} direction="column">
            <Box mb={4} textAlign="center" display={{ xs: 'none', md: 'block' }}>
              <DfpAdSlot adUnit="Web_JetsoBee/jetsobee_ros_lrec_300x250" sizes={[300, 250]} />
            </Box>
            <Box mb={4} textAlign="center" display={{ xs: 'none', md: 'block' }}>
              <DfpAdSlot adUnit="Web_JetsoBee/jetsobee_ros_lrec4_300x250" sizes={[300, 250]} />
            </Box>
            <Box mb={4} textAlign="center" display={{ xs: 'none', md: 'block' }}>
              <FacebookPage />
            </Box>
            <Box mb={4} textAlign="center" display={{ xs: 'none', md: 'block' }}>
              <SubcategoryBlock />
            </Box>
          </Grid>
        </Grid>
        </Box>
      </Container>
    </Layout>
  )
}
export default PostsPage

export const query = graphql`
  query MyQuery($limit: Int!, $skip: Int!, $today: Date) {
      allPrismicPost(
      sort: { fields: data___date, order: DESC }
      limit: $limit
      skip: $skip
      filter: {
        data: {
          date: {
            lte: $today
          }
        }
      }
    )  {
        nodes {
          prismicId
          last_publication_date
          uid
          type
          data {
            date
            title
            post_category {
              uid
              type     
              document {
                ... on PrismicPostCategory {
                  id
                  data {
                    name
                  }
                }
              }
            }
            post_subcategory {
              uid
              type
              document {
                ... on PrismicPostSubcategory {
                  id
                  data {
                    name
                    post_category {
                      uid
                    }
                  }
                }
              }
            }
            featured_images {
              image {
                url
                fluid(maxWidth: 600, maxHeight: 320) {
                  ...GatsbyPrismicImageFluid
                }
                alt
                thumbnails {
                  thumbnail {
                    fixed(height: 120, width: 120) {
                      ...GatsbyPrismicImageFixed_noBase64
                    }
                  }
                }
              }
            }
            lead {
              text
            }
            tagged {
              tag {
                uid
                type
                document {
                  ... on PrismicTag {
                    id
                    data {
                      name
                    }
                  }
                }
              }
            }
            author {
              uid
              document {
                ... on PrismicAuthor {
                  id
                  data {
                    name
                  }
                }
              }
            }
          }
        }
      pageInfo {
        currentPage
        pageCount
      }
    }
  }
`

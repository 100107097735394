/* eslint-disable no-return-assign */
/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
import React from 'react'
import Image from 'gatsby-image'
import Dotdotdot from 'react-dotdotdot'
import { hasPath, path, join, useWith, or, identity } from 'ramda'
import { linkResolver } from '@utils/link-resolver'
import Box from '@lib/ui/core/Box'
import Skeleton from "@lib/ui/core/Skeleton"
import Hidden from '@lib/ui/core/Hidden'
import Typography from '@lib/ui/core/Typography'
import Link from '@components/Link'
import TagList from '@components/Tag/List'
import Grid from "@lib/ui/core/Grid"
import { addGaEvent } from '@utils/ga-event'

const renderImage = (images, aspectRatio = 1.67777) => {
  const hasCoverImage = hasPath(["url"], images)
  const isGatsbyImage = hasPath(['thumbnails', 'thumbnail', 'fluid'], images) || hasPath([0, 'image', 'thumbnails', 'thumbnail', 'fluid'], images)
  const thumbnailPath = hasCoverImage ? path(['thumbnails', 'thumbnail', 'fluid'], images) : path([0, 'image', 'thumbnails', 'thumbnail', 'fluid'], images)
  const urlPath = hasCoverImage ? path(["url"], images) : path([0, "image", "url"], images)
  const altPath = hasCoverImage ? path(["alt"], images) : path([0, "image", "alt"], images)
  const width = [60, 120, 240, 360, 480]

  const parsedImage = isGatsbyImage
    ? {
      ...thumbnailPath,
      aspectRatio,
    }
    : {
      src: `${urlPath}&w=${
        width[2]
      }&h=${Math.floor(width[2] / aspectRatio)}&fit=crop&q=50`,
      srcSet: join(
        ", ",
        width.map(
          (w) => `${urlPath}&w=${w}&h=${Math.floor(w / aspectRatio)}&fit=crop&q=50 ${w}w`
        )
      ),
      sizes: "",
      aspectRatio,
    }

  return (
    <Image fluid={parsedImage} alt={altPath} />
  )
}

const PostCard = ({ post, fullWidth = false, loading = false }) => {
  const { featured_images, title, post_subcategory, lead, tagged, author, date } = post || {}
  const isLoadingOr = useWith(or, [identity, identity])(loading)

  const handleTagClick = tag => {
    addGaEvent({
      ga4Event: "tag_click",
      eventParams: {
        item_name: tag.name,
        content_type: "post",
        item_type: "post_tag",
      },
    })
  }

  const ImageSection = ({ aspectRatio }) => (
    <Link to={linkResolver(post)} data-ele-name="post-image">
      <Box width="100%" display="inline">
        { renderImage(featured_images, aspectRatio) }
      </Box>
    </Link>
  )

  const DescriptionSection = () => (
    <Box px={1.75} pt={1.5} pb={2}>
      { isLoadingOr(hasPath(['name'], post_subcategory)) && (
        <Box mb={0.5}>
          <Link to={linkResolver(post_subcategory)} data-ele-name="post-subcategory">
            {!loading ? post_subcategory.name : <Skeleton width="20%" />}
          </Link>
        </Box>
      )}
      { isLoadingOr(title) && (
        <Box mb={1.25}>
          <Link to={linkResolver(post)} color="text.primary">
            <Typography variant="h3" component={Dotdotdot} clamp={2} tagName="h3">
              {!loading ? (
                title
              ) : (
                <>
                  <Skeleton width="100%" />
                  <Skeleton width="60%" />
                </>
              )}
            </Typography>
          </Link>
        </Box>
      )}

      { isLoadingOr(hasPath(['text'], lead)) && (
      <Link to={linkResolver(post)} data-ele-name="post-description" color="text.primary">
        <Box mb={1.75}>
          <Typography variant="body1" component={Dotdotdot} clamp={3} tagName="p">
            {!loading ? lead.text : (
              <>
                <Skeleton />
                <Skeleton />
              </>
            )}
          </Typography>
        </Box>
      </Link>
      )}

      { !loading ? (tagged && (<TagList tags={tagged} type="post" handleTagClick={handleTagClick} />)) : <Skeleton width="40%" /> }

      {isLoadingOr(date) && (
        <Box mb={1.25}>
          <Typography variant="subtitle1">
            {!loading ? date : <Skeleton width="40%" />}
          </Typography>
        </Box>
      )}
    </Box>
  )

  return (
    <Box boxShadow={9} borderRadius="1rem" height="100%" overflow="hidden" bgcolor="background.default">
      {fullWidth ? (
        <>
          <Hidden xsDown>
            <Grid container alignItems="stretch" spacing={2}>
              {isLoadingOr(hasPath([0, 'image', 'url'], featured_images)) && (
                <Grid item sm={6} md={4} lg={5}>
                  {!loading ? (
                    <ImageSection aspectRatio={1} />
                  ) : (
                    <Box pb="100%" clone>
                      <Skeleton variant="rect" width="100%" />
                    </Box>
                  )}
                </Grid>
              )}
              <Grid item sm={5} md={7} lg={7}>
                <DescriptionSection />
              </Grid>
            </Grid>
          </Hidden>
          <Hidden smUp>
            <ImageSection aspectRatio={1.7778} /><DescriptionSection />
          </Hidden>
        </>
      )
        : <><ImageSection aspectRatio={1.7778} /><DescriptionSection /></>}
    </Box>
  )
}

export default PostCard
